type GenericObject = { [key: string]: unknown }

function isObjectOrArray(obj: unknown): obj is GenericObject | Array<unknown> {
  return obj !== null && (typeof obj === 'object' || Array.isArray(obj))
}

/**
 * Recursively redacts specified fields from an object, an array of objects, or any other type.
 *
 * @param obj - The input to be processed. It can be of any type.
 * @param fieldsToRedact - An array of strings representing the keys of the fields to be redacted.
 *
 * @returns The processed input with specified fields redacted in objects. If the input is not an object,
 *          it is returned as is.
 */
export function redactFields<T>(obj: T, fieldsToRedact: string[]): T {
  // Handle non-object and non-array types by returning them as is
  if (!isObjectOrArray(obj)) return obj

  // Handle arrays and objects uniformly
  if (Array.isArray(obj)) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return obj.map((item) => redactFields(item, fieldsToRedact)) as unknown as T
  } else {
    const redactedObj: GenericObject = {}
    for (const key of Object.keys(obj)) {
      // eslint-disable-next-line security/detect-object-injection
      const value = obj[key]
      if (typeof value === 'string') {
        // eslint-disable-next-line security/detect-object-injection
        redactedObj[key] = fieldsToRedact.includes(key) ? '[REDACTED]' : value
      } else {
        // eslint-disable-next-line security/detect-object-injection
        redactedObj[key] = redactFields(value, fieldsToRedact)
      }
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return redactedObj as T
  }
}
