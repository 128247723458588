/**
 * Define environment variables that you need exposed in the client-side bundle.
 * These should not include sensitive secrets!
 * See docs/environment-variables.md for more details.
 */
const PUBLIC_ENV_VARS_BY_ENV = {
  development: {
    ENABLE_FEEDBACK: true,
  },
  test: {
    ENABLE_FEEDBACK: true,
  },
  production: {
    ENABLE_FEEDBACK: true,
  },
} as const

const NEXT_ENVS = ['development', 'test', 'production'] as const
export type NextPublicAppEnv = (typeof NEXT_ENVS)[number]

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const CURRENT_ENV = (process.env.NEXT_PUBLIC_APP_ENV ??
  'development') as NextPublicAppEnv

// eslint-disable-next-line security/detect-object-injection
export const PUBLIC_ENV = PUBLIC_ENV_VARS_BY_ENV[CURRENT_ENV]
