import { createContext, Dispatch, ReactNode, SetStateAction } from 'react'

export interface PageErrorConfig {
  // Translated error alert heading
  heading: ReactNode
  // Translated error message body
  body: ReactNode
}

export type PageErrorContextType = {
  errorConfig?: PageErrorConfig | null
  setErrorConfig: Dispatch<SetStateAction<PageErrorConfig | null>>
}

export const PageErrorContext = createContext<PageErrorContextType>({
  errorConfig: null,
  setErrorConfig: () => {
    return null
  },
})
