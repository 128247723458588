import { trackEvent } from 'monitoring/events'

import { useEffect } from 'react'

import logger from '../monitoring/logger'

/**
 * Automatically track click events on anchor (`<a>`) elements.
 *
 * Usage:
 * Ensure your anchor tags include a `data-event-name` attribute with a unique
 * value for each distinct link you wish to track.
 *
 * @example
 * function App() {
 *   useClickTracker();
 *   return (
 *     <div>
 *       <a href="https://example.com" data-event-name="exampleLinkClick">Click me</a>
 *     </div>
 *   );
 * }
 */
const useClickTracker = () => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target
      // Check if the target is an anchor element with the required data attribute
      if (
        target instanceof Element &&
        target.tagName === 'A' &&
        target.hasAttribute('data-event-name')
      ) {
        const eventName = target.getAttribute('data-event-name')
        const openInNewTab = target.getAttribute('target') === '_blank'
        const href = target.getAttribute('href')

        if (!openInNewTab) event.preventDefault()

        try {
          if (eventName) trackEvent(eventName, { event_category: 'Link Click' })
        } catch (error) {
          logger.error({
            message: 'Failed to track custom event through click tracker',
            data: { error },
          })
        } finally {
          if (!openInNewTab && href) window.location.href = href
        }
      }
    }

    // Attach the event listener to the document
    document.addEventListener('click', handleClick)

    // Cleanup function to remove the event listener
    return () => document.removeEventListener('click', handleClick)
  }, [])
}

export default useClickTracker
