import { useSession } from 'next-auth/react'

// WhoAmI values are set as optional due to a Truss [comment](https://github.com/newjersey/dol-ui-claimant-intake/pull/614#discussion_r1003699968)
// "For actual forgerock users ... there's no guarantee outside of a username (which we don't use), so I wanted the app to forced to handle the case of missing data starting at the TS definitions"
export type WhoAmI = {
  cognitoId?: string
  forgerockId?: string
  firstName?: string
  lastName?: string
  middleInitial?: string
  birthdate?: string
  email?: string
  phone?: string
}

const changeExpirationToDate = (sessionExpiration?: string) => {
  if (typeof sessionExpiration === 'string') {
    return new Date(sessionExpiration)
  }
  return sessionExpiration
}
export const useWhoAmI = () => {
  const { data, status } = useSession()
  const whoAmI = data?.whoAmI
  const sessionExpires = changeExpirationToDate(data?.expires)
  const isLoading = status === 'loading'
  return { data: whoAmI, isLoading, sessionExpires }
}
