import { getAwsRumInstance } from './cloudwatch-rum'
import logger from './logger'

export interface EventAttributes {
  [key: string]: string | number
}

/**
 * Track custom event for GA
 *
 * Event collection limits for GA4
 * https://support.google.com/analytics/answer/9267744?sjid=6113814976598544750-NC
 *
 * Event developer guide
 * https://developers.google.com/tag-platform/gtagjs/reference#event
 *
 * @param eventName - GA event name max length is 40 characters
 * @param attributes - GA attributes, name 40 characters, value 100 characters
 */
function trackEventForGA4(eventName: string, attributes: EventAttributes = {}) {
  // We verify gtag is defined below b/c it will be undefined in all environments
  // except production (and can also be impacted by ad blockers)
  if (typeof globalThis.gtag !== 'function') return

  const name = eventName.slice(0, 40)

  // Format attributes for GA4, respecting character limits for parameter names and values
  const gaAttributes = Object.fromEntries(
    Object.entries(attributes).map(([key, value]) => [
      key.slice(0, 40),
      typeof value === 'string' ? value.slice(0, 100) : value,
    ])
  )

  globalThis.gtag('event', name, gaAttributes)
}

/**
 * Track a custom event
 *
 * @param eventName - max length of 255
 * @example trackEvent('clicked-idMe-button', { application_id: '123' })
 */
export function trackEvent(eventName: string, attributes = {}) {
  const name = eventName
    .replaceAll(/\s/g, '_')
    .replaceAll(/[^A-Za-z0-9_.-]/g, '')
    .slice(0, 255)

  trackEventForGA4(name, attributes)

  getAwsRumInstance()?.recordEvent(name, attributes)

  // Only log when running the app locally, to support debugging
  // eslint-disable-next-line no-node-env/no-node-env
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.table({ event_name: name, ...attributes })
  }
}

/**
 * Track a custom event recording a client side Error
 *
 * @example
 * trackError('custom-event-name', exception, { attributes })
 */
export function trackError(exception: Error) {
  logger.error({ message: 'tracking error', data: { error: exception } })
  getAwsRumInstance()?.recordError(exception)
}
