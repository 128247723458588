import { AwsRum, AwsRumConfig } from 'aws-rum-web'

import logger from './logger'

let awsRumInstance: AwsRum | null = null

export const initializeAWSRum = () => {
  // Make sure cloudwatch RUM is enabled for this env and the window is defined
  if (isAWSRumDisabled()) return null

  try {
    const APPLICATION_ID =
      process.env.NEXT_PUBLIC_CLOUDWATCH_RUM_APPLICATION_ID || ''
    const APPLICATION_VERSION = '1.0.0'
    const APPLICATION_REGION = 'us-east-1'

    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: process.env.NEXT_PUBLIC_CLOUDWATCH_RUM_GUEST_ROLE_ARN || '',
      identityPoolId:
        process.env.NEXT_PUBLIC_CLOUDWATCH_RUM_IDENTITY_POOL_ID || '',
      endpoint: `https://dataplane.rum.${APPLICATION_REGION}.amazonaws.com`,
      telemetries: [
        'performance',
        'errors',
        [
          'http',
          {
            recordAllRequests: true,
          },
        ],
      ],
      allowCookies: true,
      enableXRay: true,
    }

    awsRumInstance = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    )
  } catch (error) {
    logger.error({ message: 'Failed to initialize AWS RUM', data: { error } })
  }
  return awsRumInstance
}

export const getAwsRumInstance = (): AwsRum | null => {
  if (!awsRumInstance) return initializeAWSRum()

  return awsRumInstance
}

function isAWSRumDisabled() {
  const isServerSide = typeof window === 'undefined'

  const isEnvVarMissing =
    !process.env.NEXT_PUBLIC_CLOUDWATCH_RUM_GUEST_ROLE_ARN ||
    !process.env.NEXT_PUBLIC_CLOUDWATCH_RUM_IDENTITY_POOL_ID ||
    !process.env.NEXT_PUBLIC_CLOUDWATCH_RUM_APPLICATION_ID

  return isEnvVarMissing || isServerSide
}
