import { useSession } from 'next-auth/react'
import { useEffect } from 'react'

import { getAwsRumInstance } from '../../monitoring/cloudwatch-rum'

/**
 * Configures user-specific tracking for Google Analytics and AWS RUM based on
 * the authenticated user's Cognito ID. This component hooks into session data
 * provided by `useSession` and applies the user ID to Google Analytics using
 * the `gtag` function. It also configures AWS RUM with the user's Cognito ID
 * as a session attribute.
 *
 * The component operates side-effectively on changes to the user's session
 * and does not render any visual content.
 */
const AnalyticsConfigurator = () => {
  const { data: session } = useSession()

  useEffect(() => {
    const cognitoId = session?.whoAmI?.cognitoId
    if (cognitoId) {
      if (typeof globalThis.gtag === 'function')
        globalThis.gtag('set', 'user_id', cognitoId)

      const awsRum = getAwsRumInstance()
      if (awsRum) awsRum.addSessionAttributes({ cognitoId })
    }
  }, [session?.whoAmI.cognitoId])

  return null
}

export default AnalyticsConfigurator
