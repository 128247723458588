import {
  Level,
  Logger,
  // use the named export, rather than default export, so the OpenTelemetry
  // instrumentation correctly adds trace/span id to the server logs.
  // https://github.com/open-telemetry/opentelemetry-js-contrib/issues/1587
  pino,
} from 'pino'

import { redactFields } from './redactFields'

const redactedFields = [
  'first_name',
  'last_name',
  'middle_initial',
  'suffix',
  'address_line1',
  'address_line2',
  'alien_registration_number',
  'LOCAL_re_enter_alien_registration_number',
  'country_of_origin',
  'number', // i.e. phone numbers
  'birthdate',
  'account_number',
  'account_type',
  'routing_number',
  'LOCAL_re_enter_account_number',
  'LOCAL_re_enter_routing_number',
  'drivers_license_or_state_id_number',
  'email',
  'ssn',
  'fein',
]

type LoggerFunction =
  | Logger['info']
  | Logger['debug']
  | Logger['error']
  | Logger['warn']
  | Logger['trace']

const baseLogger = pino({
  level: process.env.NEXT_PUBLIC_APP_ENV === 'development' ? 'debug' : 'info',
  // Don't log hostname since it could include PII, like a person's name
  base: undefined,
  // Be consistent with the message key used by the Persistence API
  messageKey: 'message',
  formatters: {
    level: formatLevel,
  },
  // Next.js uses the browser Pino logger in Middleware, so we need to
  // customize it to closely match the server logger as much as possible.
  // https://github.com/pinojs/pino/issues/1315#issuecomment-1027691824
  browser: {
    asObject: true,
    serialize: true,
    write: writeBrowserLog,
  },
})

/**
 * Redacts sensitive information from log objects before logging.
 *
 * @param logMethod - The Pino logging method (e.g., error, warn, info) to be used after redaction.
 * @param logObject - The log object to be processed. It is deliberately typed as 'any' to accommodate
 *                    the diverse shapes that log objects can take. This generic approach ensures that
 *                    the redaction process is flexible and applicable to all types of log data,
 *                    regardless of their structure.
 */
export function redactAndLog(
  logMethod: LoggerFunction,
  logObject: Record<string, unknown> | string
) {
  logMethod(redactFields(logObject, redactedFields))
}

/**
 * Enhanced Logger: A Custom Wrapper around Pino's Logging Methods
 *
 * This logger extends Pino's standard logging methods (error, warn, info, debug, trace)
 * by incorporating a data redaction step. Each overridden method first invokes the
 * `redactAndLog` function, which performs two key operations:
 *
 * 1. Redaction: It scrubs sensitive information from the log object's fields, ensuring
 *    that privacy and security are upheld by preventing inadvertent exposure of sensitive data.
 *
 * 2. Delegation: It then forwards the sanitized log data to the corresponding original
 *    Pino logging method, maintaining the inherent functionality and efficiency of the Pino logger.
 *
 */
const logger = {
  error: (obj: Record<string, unknown> | string) =>
    redactAndLog(baseLogger.error.bind(baseLogger), obj),
  warn: (obj: Record<string, unknown> | string) =>
    redactAndLog(baseLogger.warn.bind(baseLogger), obj),
  info: (obj: Record<string, unknown> | string) =>
    redactAndLog(baseLogger.info.bind(baseLogger), obj),
  debug: (obj: Record<string, unknown> | string) =>
    redactAndLog(baseLogger.debug.bind(baseLogger), obj),
  trace: (obj: Record<string, unknown> | string) =>
    redactAndLog(baseLogger.trace.bind(baseLogger), obj),
  /**
   * @see https://getpino.io/#/docs/child-loggers
   */
  child: (bindings: Record<string, unknown>) => baseLogger.child(bindings),
}

function formatLevel(label: string, number: number) {
  // Use the same keys and casing as the Persistence API
  return { levelno: number, levelname: label.toUpperCase() }
}

const levelNumberToLabel: { [key: number]: Level } = {
  10: 'trace',
  20: 'debug',
  30: 'info',
  40: 'warn',
  50: 'error',
  60: 'fatal',
}

function writeBrowserLog(obj: object) {
  try {
    const levelAttrs =
      'level' in obj && typeof obj.level === 'number'
        ? formatLevel(levelNumberToLabel[obj.level], obj.level)
        : {}

    const logObject = { ...obj, ...levelAttrs }
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(logObject))
  } catch (err) {
    if (err instanceof Error) {
      // Without a `replacer` argument, stringify on Error results in `{}`
      // eslint-disable-next-line no-console
      console.log(JSON.stringify(err, ['name', 'message', 'stack']))
    }

    // eslint-disable-next-line no-console
    console.log(JSON.stringify({ message: 'Unknown error type' }))
  }
}

export default logger
