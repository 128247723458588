import { MaintenanceBody } from 'pages/maintenance'
import { useMaintenanceCheck } from 'queries/useMaintenanceCheck'

import { useRouter } from 'next/router'

import PageLoader from 'components/loaders/PageLoader'

/**
 * Check if the app is in maintenance mode. If it is, show a maintenance message.
 * Although not the most ideal, we do this check on the client side because we
 * ran into a number of challenges checking the feature flag within middleware.
 * Learn more: https://github.com/newjersey/dol-ui-claimant-intake/issues/4793
 */
export function MaintenanceChecker(props: { children: React.ReactNode }) {
  const router = useRouter()
  const { data: isEnabled, isLoading } = useMaintenanceCheck(
    // We want to check the maintenance status each time the user navigates to
    // a new page, so we use the page's path as the cache key
    router.pathname
  )

  if (isLoading) {
    return <PageLoader />
  }

  if (isEnabled) {
    // Rather than redirect to the maintenance page, we keep the user on
    // the current page and show a maintenance message. This way, if they
    // refresh their page and it's no longer maintenance mode, they'll
    // land back on the page they were originally trying to access.
    return <MaintenanceBody />
  }

  return props.children
}
