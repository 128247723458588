import {
  ApplicationContext,
  ApplicationContextType,
} from 'contexts/ApplicationContext'
import {
  ClaimFormContext,
  ClaimFormContextType,
} from 'contexts/ClaimFormContext'
import { PageErrorConfig, PageErrorContext } from 'contexts/PageErrorContext'

import { useRouter } from 'next/router'
import { ReactNode, useEffect, useState } from 'react'

type AppContextProvidersProps = {
  children: ReactNode
}
export const AppContextProviders = ({ children }: AppContextProvidersProps) => {
  const router = useRouter()
  const [claimFormValues, setClaimFormValues] = useState<
    ClaimFormContextType['claimFormValues']
  >({})
  const [errorConfig, setErrorConfig] = useState<PageErrorConfig | null>(null)
  const [reopenerData, setReopenerData] = useState<
    ApplicationContextType['applicationData'] | null
  >(null)

  /**
   * @see https://nextjs.org/docs/pages/api-reference/functions/use-router#routerevents
   */
  const handleRouteChangeComplete = () => {
    // We don't want to display a previous page's error on a new page,
    // since those errors wouldn't be applicable to the new page:
    setErrorConfig(null)
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [router])

  return (
    <PageErrorContext.Provider value={{ errorConfig, setErrorConfig }}>
      <ClaimFormContext.Provider
        value={{ claimFormValues, setClaimFormValues }}
      >
        <ApplicationContext.Provider
          value={{
            applicationData: reopenerData,
            setApplicationData: setReopenerData,
          }}
        >
          {children}
        </ApplicationContext.Provider>
      </ClaimFormContext.Provider>
    </PageErrorContext.Provider>
  )
}
