import { useTranslation } from 'react-i18next'
import { Button } from '@trussworks/react-uswds'

import {
  FeedbackStepChildInterface,
  FeedbackSteps,
} from 'components/Feedback/Feedback'

export const InitialQuestion = ({ setStep }: FeedbackStepChildInterface) => {
  const { t } = useTranslation('common', { keyPrefix: 'feedback' })

  const handleClicked = () => {
    setStep(FeedbackSteps.FeedbackForm)
  }

  return (
    <>
      <Button
        onClick={handleClicked}
        type="button"
        className="usa-button--unstyled"
      >
        {t('question')}
      </Button>
    </>
  )
}
