import { useTranslation } from 'react-i18next'
import Head from 'next/head'

export default function Maintenance() {
  const { t } = useTranslation('maintenance')

  return (
    <>
      <Head>
        <title>{t('page_title')}</title>
      </Head>
      <MaintenanceBody />
    </>
  )
}

export function MaintenanceBody() {
  const { t } = useTranslation('maintenance')

  return (
    <main
      id="main-content"
      className="maxw-tablet desktop:margin-x-0 margin-bottom-3"
    >
      <h1 data-testid="maintenance-page-heading">{t('heading')}</h1>
      <p>{t('p1')}</p>
    </main>
  )
}
