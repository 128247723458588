import { trackError } from 'monitoring/events'

import { withTranslation, WithTranslation } from 'react-i18next'
import { Component } from 'react'
import { Button } from '@trussworks/react-uswds'

interface ErrorBoundaryProps extends WithTranslation {
  children: React.ReactNode
}

/**
 * Error boundaries are React components that catch JavaScript
 * errors anywhere in their child component tree, log those errors,
 * and display a fallback UI instead of the component tree that crashed.
 * @see https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 */
export class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state: { hasError: boolean }

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    trackError(error)
  }

  handleReloadClick = () => {
    window.location.reload()
  }

  render() {
    const { t } = this.props

    if (this.state.hasError) {
      return (
        <>
          <h1 className="nj-h2">{t('server_error.heading')}</h1>
          <p className="margin-bottom-205">{t('server_error.message')}</p>
          <Button type="button" onClick={this.handleReloadClick}>
            {t('server_error.refresh')}
          </Button>
        </>
      )
    }

    return this.props.children
  }
}

export default withTranslation('errorPages')(ErrorBoundary)
