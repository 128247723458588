import { LOCALES } from 'constants/locales'
import { Routes } from 'constants/routes'

import { useTranslation } from 'react-i18next'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import {
  ExtendedNav,
  Header,
  Menu,
  NavDropDownButton,
  NavMenuButton,
  Title,
} from '@trussworks/react-uswds'

import { SignOut } from 'components/SignOut/SignOut'

export const NewJerseyHeader = () => {
  const session = useSession()
  const { t } = useTranslation('common', { keyPrefix: 'header' })
  const router = useRouter()
  const [expanded, setExpanded] = useState(false)
  const handleToggleMobileNavClick = () =>
    setExpanded((prvExpanded) => !prvExpanded)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const isLoggedIn = session.data?.user !== undefined
  const claimantName = [
    session.data?.whoAmI?.firstName,
    session.data?.whoAmI?.middleInitial,
    session.data?.whoAmI?.lastName,
  ]
    .join(' ')
    .trim()

  const myAccountDropdownItems = [
    <Link
      href={Routes.UPDATE_PAYMENT_INFO}
      key="Direct deposit"
      target="_blank"
    >
      {t('direct_deposit')}
    </Link>,
    <Link
      href={Routes.UPDATE_CONTACT_INFO}
      key="Contact information"
      target="_blank"
    >
      {t('contact_information')}
    </Link>,
    <Link href={Routes.TAX_DOCUMENTS} key="Tax form" target="_blank">
      {t('tax_form')}
    </Link>,
  ]

  const loggedInMenuItems = [
    <Link
      href={Routes.HOME}
      key="home"
      aria-current={router.asPath === Routes.HOME}
      className={router.asPath === Routes.HOME ? 'usa-current' : ''}
    >
      {t('home')}
    </Link>,
    <Link
      href={Routes.PRIVACY}
      key="privacy"
      aria-current={router.asPath === Routes.PRIVACY}
      className={router.asPath === Routes.PRIVACY ? 'usa-current' : ''}
    >
      {t('privacy')}
    </Link>,
    <>
      <NavDropDownButton
        menuId="myAccountDropDownMenu"
        onToggle={() => setIsDropdownOpen(!isDropdownOpen)}
        label={t('account')}
        isOpen={isDropdownOpen}
      />
      <Menu
        key="myAccountDropDownMenu"
        items={myAccountDropdownItems}
        isOpen={isDropdownOpen}
        id="myAccountDropDownMenu"
      />
    </>,
    <SignOut isNavLink key="logout" />,
  ]

  const loggedOutMenuItems = [
    <Link href={Routes.SIGNIN} data-testid="header-signin-button" key="signin">
      {t('signin')}
    </Link>,
  ]

  const secondaryItems = [
    claimantName && (
      <span className="font-sans-2xs text-base">
        {t('sign_in_name', { name: claimantName })}
      </span>
    ),
    <Link
      aria-label={
        router.locale === LOCALES.spanish
          ? t('languageToggle.englishHelpText')
          : t('languageToggle.spanishHelpText')
      }
      data-testid="languageToggle"
      href={router.asPath}
      key="languageToggle"
      locale={
        router.locale === LOCALES.english ? LOCALES.spanish : LOCALES.english
      }
    >
      {router.locale === LOCALES.spanish
        ? t('languageToggle.english')
        : t('languageToggle.spanish')}
    </Link>,
  ].filter((elm) => elm)
  return (
    <>
      <div
        data-testid="overlay"
        className={`usa-overlay ${expanded ? 'is-visible' : ''}`}
      />
      <Header basic className="border-bottom border-base-lighter">
        <div className="usa-nav-container">
          <div className="usa-navbar">
            <Title>{t('title')}</Title>
            <NavMenuButton
              onClick={handleToggleMobileNavClick}
              label={t('menu')}
            />
          </div>
          <ExtendedNav
            primaryItems={isLoggedIn ? loggedInMenuItems : loggedOutMenuItems}
            secondaryItems={secondaryItems}
            mobileExpanded={expanded}
            onToggleMobileNav={handleToggleMobileNavClick}
          />
        </div>
      </Header>
    </>
  )
}
