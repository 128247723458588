import axios from 'axios'
import { useQuery } from 'react-query'

const getMaintenanceModeStatus = async () => {
  const responseData = await axios.get<{ isEnabled: boolean }>(
    `/api/maintenance`
  )
  return responseData.data.isEnabled
}

/**
 * Check if maintenance mode feature flag is enabled. This intentially not a generic
 * feature flag query hook, because in most other cases, we should do
 * the feature flag check on the server side. This one is an exception
 * since we ran into challenges checking the feature flag within middleware.
 * Learn more: https://github.com/newjersey/dol-ui-claimant-intake/issues/4793
 */
export const useMaintenanceCheck = (cacheKey: string) => {
  return useQuery(['maintenance', cacheKey], getMaintenanceModeStatus, {
    // Avoid making the same request multiple times if a user goes back and forth between the same pages
    staleTime: 1000 * 60 * 1, // 1 minute
  })
}
