import dynamic from 'next/dynamic'
import { usePathname } from 'next/navigation'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { GridContainer } from '@trussworks/react-uswds'

import { InitialQuestion } from './steps/InitialQuestion/InitialQuestion'

export const enum FeedbackSteps {
  InitialQuestion,
  FeedbackForm,
  EmailCapture,
  ThankYou,
}

export interface FeedbackStepChildInterface {
  setStep: Dispatch<SetStateAction<FeedbackSteps>>
}

interface FeedbackStepInterface {
  step: FeedbackSteps
  setStep: Dispatch<SetStateAction<FeedbackSteps>>
}

const FeedbackForm = dynamic(() =>
  import('./steps/FeedbackForm/FeedbackForm').then((mod) => mod.FeedbackForm)
)

const EmailCapture = dynamic(() =>
  import('./steps/EmailCapture/EmailCapture').then((mod) => mod.EmailCapture)
)

const ThankYou = dynamic(() =>
  import('./steps/ThankYou/ThankYou').then((mod) => mod.ThankYou)
)

const FeedbackStep = ({ step, setStep }: FeedbackStepInterface) => {
  switch (step) {
    case FeedbackSteps.InitialQuestion:
    default:
      return <InitialQuestion setStep={setStep} />
    case FeedbackSteps.FeedbackForm:
      return <FeedbackForm setStep={setStep} />
    case FeedbackSteps.EmailCapture:
      return <EmailCapture setStep={setStep} />
    case FeedbackSteps.ThankYou:
      return <ThankYou />
  }
}

export const Feedback = () => {
  const [step, setStep] = useState<FeedbackSteps>(FeedbackSteps.InitialQuestion)
  const pathname = usePathname()

  useEffect(() => {
    setStep(FeedbackSteps.InitialQuestion)
  }, [pathname])

  return (
    <div
      data-testid="feedback"
      className="maxw-full minh-9 padding-y-3 bg-base-lightest"
    >
      <GridContainer>
        <FeedbackStep step={step} setStep={setStep} />
      </GridContainer>
    </div>
  )
}
