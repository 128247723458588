import { createContext } from 'react'

interface ApplicationData {
  isVerified?: boolean
  canReopen?: boolean | null
}

export interface ApplicationContextType {
  applicationData: ApplicationData | null
  setApplicationData: (data: ApplicationData) => void
}

export const ApplicationContext = createContext<ApplicationContextType>({
  applicationData: null,
  setApplicationData: (_formData: unknown) => {
    return
  },
})
