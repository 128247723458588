import { PartialApplication } from 'models/application'

import { createContext } from 'react'

export type ClaimFormValues = PartialApplication

export type ClaimFormContextType = {
  claimFormValues: ClaimFormValues
  setClaimFormValues: (formData: Partial<ClaimFormValues>) => void
  maskSensitiveData?: boolean
}

export const ClaimFormContext = createContext<ClaimFormContextType>({
  claimFormValues: {},
  setClaimFormValues: (_formData: unknown) => {
    return
  },
})
